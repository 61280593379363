import React from "react";
import { Helmet } from "react-helmet";
const Contact=()=>{

    return(
        <div>
          <Helmet>
        <title>Contact Trice Technologies | Get in Touch Today</title>
        <meta name='title' content='Contact Trice Technologies | Get in Touch Today'/>
        <meta name="description" content="Get a FREE consultation with Trice Technologies! Discuss your project needs and explore how our expertise can benefit you. Contact us today!" />
        <meta name="keywords" content="web design Palakkad, website design in Kerala, Palakkad web development company, web designing Palakkad, website design company in Palakkad, web development company in Palakkad, domain registration Palakkad, web Hosting company Palakkad" />
        <link rel="canonical" href="https://tricetechnologies.in/Contact-Trice-Technologies-Get-in-Touch-Today" />
        
        <meta property="og:title" content="Contact Trice Technologies | Get in Touch Today" />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://tricetechnologies.in/Contact-Trice-Technologies-Get-in-Touch-Today" />
        <meta property="og:image" content="https://tricetechnologies.in/Logo-Dark.png" />
        <meta property="og:description" content="Get a FREE consultation with Trice Technologies! Discuss your project needs and explore how our expertise can benefit you. Contact us today!" />
      </Helmet>
            <section class="section section-intro context-dark">
            <div class="intro-bg" style={{
       backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg1.webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
      }}></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-8 text-center">
                  <h1 class="font-weight-bold wow" data-aos='fade-left'>Contact Us</h1>
                  <p class="intro-description wow" data-aos='fade-right'>We are always happy to help you!</p>
                </div>
              </div>
            </div>
          </section>

          <section>
    
            <div class="section-header">
              <div class="container contact">
                <h2>Contact Us</h2>
                <p>Your next big idea could start with a simple hello.<br></br> Contact us today and let’s explore how we can work together to achieve your goals.</p>
              </div>
            </div>
            
            <div class="container">
              <div class="row">
                
                <div class="contact-info">
                  <div class="contact-info-item">
                    <div class="contact-info-icon">
                      <i class="fas fa-home"></i>
                    </div>
                    
                    <div class="contact-info-content">
                      <h4>Address</h4>
                      <p>2nd Floor, Prime Complex,<br/> Kalmandapam, <br/> Palakkad <br/>678013</p>
                    </div>
                  </div>
                  
                  <div class="contact-info-item">
                    <div class="contact-info-icon">
                      <i class="fas fa-phone"></i>
                    </div>
                    
                    <div class="contact-info-content">
                      <h4>Phone</h4>
                      <p>+91 9142121210</p>
                    </div>
                  </div>
                  
                  <div class="contact-info-item">
                    <div class="contact-info-icon">
                      <i class="fas fa-envelope"></i>
                    </div>
                    
                    <div class="contact-info-content">
                      <h4>Email</h4>
                     <p>info@tricetechnologies.in</p>
                    </div>
                  </div>
                </div>
                
                <div class="contact-form">
                  <form action="" id="contact-form">
                    <h2>Send Message</h2>
                    <div class="input-box">
                      <input type="text" required="true" name=""/>
                      <span>Full Name</span>
                    </div>
                    
                    <div class="input-box">
                      <input type="email" required="true" name=""/>
                      <span>Email</span>
                    </div>
                    
                    <div class="input-box">
                      <textarea required="true" name=""></textarea>
                      <span>Type your Message...</span>
                    </div>
                    
                    <div class="input-box">
                      <input type="submit" value="Send" name=""/>
                    </div>
                  </form>
                </div>
                
              </div>
            </div>
          </section>

          <iframe style={{width: '100%',height: '100vh'}} loading="lazy"
					src="https://maps.google.com/maps?q=trice%20technologies%2C%20palakkad&#038;t=m&#038;z=15&#038;output=embed&#038;iwloc=near"
					title="trice technologies, palakkad"
					aria-label="trice technologies, palakkad"
			></iframe>
        </div>
    )
}

export default Contact;