import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

function ProjectsSection() {
  const [activeTab, setActiveTab] = useState("Content Creation");
  const [contentVisible, setContentVisible] = useState(true);

  const handleTabChange = (tab) => {
    setContentVisible(false); // Start transition
    setTimeout(() => {
      setActiveTab(tab);
      setContentVisible(true); // Complete transition
    }, 300); // Adjust to match transition duration in CSS (0.3s = 300ms)
  };

  // Define sample project data
  const projects = [
    { title: "Project 1", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_03.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 6", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_09.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 7", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_10.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 2", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_04.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 8", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_11.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 9", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_12.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 10", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_06.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 3", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_02.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 11", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_13.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 12", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_08.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 4", category: "Digital Marketing", image: `${process.env.PUBLIC_URL}/images/Projects/Digital_Marketing_07.webp`,alt:
    "digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" },
    { title: "Project 13", category: "Content Creation", image: `${process.env.PUBLIC_URL}/images/Projects/Content-Creation-01.webp`,alt:'Content Creation' },
    { title: "Project 14", category: "Content Creation", image: `${process.env.PUBLIC_URL}/images/Projects/Content-Creation-02.webp`,alt:'Content Creation' },
    { title: "Project 15", category: "Content Creation", image: `${process.env.PUBLIC_URL}/images/Projects/Content-Creation-03.webp`,alt:'Content Creation'},
    { title: "Project 16", category: "Content Creation", image: `${process.env.PUBLIC_URL}/images/Projects/Content-Creation-04.webp`,alt:'Content Creation' },
    { title: "Project 17", category: "Content Creation", image: `${process.env.PUBLIC_URL}/images/Projects/Content-Creation-05.webp`,alt:'Content Creation' },
    { title: "Project 18", category: "Content Creation", image: `${process.env.PUBLIC_URL}/images/Projects/Content-Creation-06.webp`,alt:'Content Creation' },
    { title: "Project 19", category: "Content Creation", image: `${process.env.PUBLIC_URL}/images/Projects/Content-Creation-07.webp`,alt:'Content Creation' },
    { title: "Project 20", category: "Content Creation", image: `${process.env.PUBLIC_URL}/images/Projects/Content-Creation-08.webp`,alt:'Content Creation' },
    { title: "Project 21", category: "Content Creation", image: `${process.env.PUBLIC_URL}/images/Projects/Content-Creation-09.webp`,alt:'Content Creation' },
    { title: "Project 22", category: "Web Development", image: `${process.env.PUBLIC_URL}/images/Projects/website-1.webp`,alt:
    "Best web development palakkad,webdesign palakkad,webdeveolpment palakkad" },
    { title: "Project 23", category: "Web Development", image: `${process.env.PUBLIC_URL}/images/Projects/website-2.webp`,alt:
    "Best web development palakkad,webdesign palakkad,webdeveolpment palakkad" },
    { title: "Project 24", category: "Web Development", image: `${process.env.PUBLIC_URL}/images/Projects/website-3.webp`,alt:
    "Best web development palakkad,webdesign palakkad,webdeveolpment palakkad" },
    { title: "Project 25", category: "Web Development", image: `${process.env.PUBLIC_URL}/images/Projects/website-4.webp`,alt:
    "Best web development palakkad,webdesign palakkad,webdeveolpment palakkad" },
    { title: "Project 26", category: "Web Development", image: `${process.env.PUBLIC_URL}/images/Projects/website-5.webp`,alt:
    "Best web development palakkad,webdesign palakkad,webdeveolpment palakkad" },
    { title: "Project 27", category: "Web Development", image: `${process.env.PUBLIC_URL}/images/Projects/website-6.webp`,alt:
    "Best web development palakkad,webdesign palakkad,webdeveolpment palakkad"},
    { title: "Project 28", category: "Web Development", image: `${process.env.PUBLIC_URL}/images/Projects/website-7.webp`,alt:
    "Best web development palakkad,webdesign palakkad,webdeveolpment palakkad" },
    { title: "Project 29", category: "Web Development", image: `${process.env.PUBLIC_URL}/images/Projects/website-8.webp`,alt:
    "Best web development palakkad,webdesign palakkad,webdeveolpment palakkad" },
    { title: "Project 30", category: "Web Development", image: `${process.env.PUBLIC_URL}/images/Projects/website-9.webp`,alt:
    "Best web development palakkad,webdesign palakkad,webdeveolpment palakkad" }
  ];

  return (
    <section className="portfolio p-section">
      <div className="container">
        <div className="top-side">
          <div className="section-name wow" data-aos='fade-right'>Portfolio</div>
          <h3 className="text-capitalize devider-left wow" data-aos='fade-left' data-wow-delay=".2s">Our<span className="text-primary"> Works</span></h3>
        </div>
        
        <Tabs
          activeKey={activeTab}
          onSelect={handleTabChange}
          id="project-tabs"
          className="filters"
        >
          <Tab eventKey="Content Creation" title="Content Creation"></Tab>
          <Tab eventKey="Digital Marketing" title="Digital Marketing"></Tab>
          <Tab eventKey="Web Development" title="Web Development"></Tab>
        </Tabs>
        
        <div className={`filters-content ${contentVisible ? '' : 'hidden'}`}>
          <div className="row grid">
            {/* Render projects based on the active tab */}
            {projects.map((project, index) => (
              // Render each project item based on the active tab
              (activeTab === project.category) && (
                <div key={index} className={`col-6 col-md-4 all ${project.category}`}>
                  <div className="item">
                    {/* Lazy load images with loading="lazy" */}
                    <img
                      src={project.image}
                      alt={project.alt}
                      className="img-fluid" // Ensure image responsiveness
                      width="800"
                      height="600"
                      loading="lazy" // Lazy loading for better performance
                    />
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectsSection;
