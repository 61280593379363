import React, { useState } from "react";


const FAQ = () => {
  // Define the state to handle which FAQ is open
  const [openIndex, setOpenIndex] = useState(null);

  // Sample FAQ data
  const faqData = [
    { question: "Why choose Trice Technologies as your SEO company in Palakkad?", answer: "We specialize in crafting personalized SEO strategies that drive higher rankings and boost organic traffic, establishing us as one of the top SEO companies in Kerala." },
    { question: "Do you provide SEO services outside Palakkad?", answer: "Absolutely! Although we’re located in Palakkad, our SEO services are available to clients everywhere. Distance is never a barrier for us. As a trusted provider of SEO services Kerala, we’re here to help businesses across the region and beyond achieve their online goals with expert strategies and dedicated support." },
    { question: "How can your digital marketing services benefit my business?", answer: "Our extensive digital marketing services, from social media marketing, are developed to uplift your brand's online presence and makes visible outcomes. For example, Trice Technologies SEO Services in Palakkad emphasises on updrading your websites's visibility on search engines, which in turs helps you to attract more customers who are actively searching for your products and services. By focusing on the right customers and enhansing your online presense, Trice can amplify both your website traffic and conversions by giving your business a competitive hand. Let's help you to raech your right audience and redirect clicks to purchase." },
    { question: "How do you help websites rank higher in search engines?", answer: "We use a combination of keyword research, on-page SEO, technical enhancements, and quality backlinks to improve your search engine rankings." },
    { question: "How long does it take to see SEO improvements?", answer: "You can typically see results within 3 to 6 months. However, our focused efforts are designed to achieve steady and long-lasting results through strategic SEO services." },
    { question: "What is the cost of your SEO services?", answer: "The cost varies depending on your business needs and the scope of the project. We offer customized SEO solutions that deliver value and results within your budget. Contact us for a tailored quote." },
    { question: "Why should I opt for Trice Technologies for digital marketing?", answer: "Choosing Trice can refer as collaborating with one of the best digital marketing agencies I Palakkad. We provide customized digital marketing solutions, efficiently designed to match with your individual business goals for great result. Trice Technologies integrates industry expertise with competitive and new strategies, aiming at data driven outcomes which enhance your brand's visibility and reach. No matter if it is Social Media, or content planning and marketing, we make sure that each capaign is designed to drive actuall outcomes and lasting success for your business." },
    { question: "How can I start using your SEO services?", answer: "Contact us now to have a kickstart.! No matter if you are in Palakkad or not, we provide our SEO services not only in Palakkad but beyond that, to grow your business. With  expert SEO and digital marketing services in Palakkad, Trice Technologies will elevate your online presence to reach the right audience. Let's help you to make your businees a success, which deliver real results." }
];

  // Toggle the open state for each FAQ item
  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close if the same index is clicked again
    } else {
      setOpenIndex(index); // Open the clicked FAQ
    }
  };

  return (
    <div className="faq-container">
      <h2>FAQs</h2>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`faq-item ${openIndex === index ? "open" : ""}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <span>{item.question}</span>
              <span className="faq-icon">{openIndex === index ? "-" : "+"}</span>
            </div>
            <div className="faq-answer">{item.answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
