import React from "react";

import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
const About=()=>{

    return(

      
        <div>

<div>
      <Helmet>
        <title>Web Design & Digital Marketing Experts in Palakkad | Trice Technologies</title>
        <meta name='title' content='Web Design & Digital Marketing Experts in Palakkad | Trice Technologies'/>
        <meta name="description" content="Trice Technologies offers complete website design, development, SEO & digital marketing solutions. Get a free quote today!" />
        <meta name="keywords" content="web design Palakkad, website design in Kerala, Palakkad web development company, web designing Palakkad, website design company in Palakkad, web development company in Palakkad, domain registration Palakkad, web Hosting company Palakkad" />
        <link rel="canonical" href="https://tricetechnologies.in/WebDesign-and-Digital-Marketing-Experts" />
        
        <meta property="og:title" content="web design & digital marketing experts in palakkad | trice technologies" />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://tricetechnologies.in/WebDesign-and-Digital-Marketing-Experts" />
        <meta property="og:image" content="https://tricetechnologies.in/images/about/about.webp" />
        <meta property="og:description" content="Trice Technologies: Web development, digital marketing, content, video & photography. Grow your business online - all-in-one solution!" />
      </Helmet>
     
    </div>
          
             <section class="section section-intro context-dark">
            <div class="intro-bg" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg1.webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
      }}></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-8 text-center">
                  <h1 class="font-weight-bold wow" data-aos='fade-up'>About Us</h1>
                  <p class="intro-description wow" data-aos='fade-right'>Feel free to learn more about our team and company on this page.<br></br> We are always happy to help you!</p>
                </div>
              </div>
            </div>
          </section>

          <section class="section section-md">
            <div class="container">
              <div class="row row-40 justify-content-center pt-5">
                <div class="col-lg-6 col-12">
                  <div class="offset-top-45 offset-lg-right-45">
                    {/* <div class="section-name wow" data-aos='fade-right' data-wow-delay=".2s">About us</div> */}
                    <h1 class="wow fadeInLeft text-capitalize" style={{fontSize: '47px'}} data-wow-delay=".3s">About<span class="text-primary"> us</span></h1>
                    {/* <p class="wow pt-3" data-aos='fade-up' data-wow-delay=".4s">Because we can TURBOCHARGE YOUR BUSINESS. We give companies a spectacular digital identity with practical solutions to generate continuous leads. So, whether you are a start-up or are an established large-scale enterprise, Trice Technologies will help you solidify your digital branding by crafting web solutions that will bring customers to your businesses.</p> */}
                    {/* <p class="wow" data-aos='fade-up' data-wow-delay=".4s">Trice Technologies…For over 15 years we blend creativity, technology, and strategy to bring the digital dreams to life. As a leading web development and digital marketing company, we specialize in crafting captivating online experiences and driving measurable results for businesses of all sizes. We believe that a strong online presence is vital in today's digital landscape. Our team of talented web developers, designers, and digital marketers are passionate about creating websites and digital campaigns that not only look stunning but also deliver exceptional user experiences and generate tangible business growth.</p> */}
                    {/* <p class="wow" data-aos='fade-up' data-wow-delay=".4s">Let us bring your digital dreams to life and make your online presence shine. Join us on this exciting journey today!</p> */}
                    <h2 class="wow about-content" data-aos='fade-up' data-wow-delay=".4s">Trice Technologies is a Website Designing and Developing company in Palakkad, Kerala offering everything you need to establish your business online including Web Design & Development, SEO, Social Media Marketing, Mutimedia, Softwares, Corporate Identity, Logo Designing, Product Prsentation, Business ID solutions, Industrial Product Photography, Promo Videos and Bulk SMS Solutions.</h2>
                    <h2 class="wow about-content" data-aos='fade-up' data-wow-delay=".4s">We have experienced website designers and web developers. We make sure that any person visiting your website get a pleasurable online experience. If you are looking for a web design agency to help you get success in building digital awareness of your business and increase your revenue, then we think you will be glad to meet us. So what are you waiting for? Contact us for a free quote.</h2>

                  </div>
                </div>
                <div class="col-lg-6 col-sm-10 col-12 mt-3">
                  <div class="block-decorate-img wow" data-aos='fade-up' data-wow-delay=".2s"><img src="images/about/about.webp" alt="" width="570" height="351"/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="serve pt-4" data-aos='zoom-in'>
            <p>
                <span class="tag"><a href="#">Web Designing</a></span>
        <span class="tag"><a href="#">Mobile Development</a></span>
        <span class="tag"><a href="#">Ecommerce Application</a></span>
        <span class="tag"><a href="#">Custom Software</a></span>
        <span class="tag"><a href="#">Business Whatsapp</a></span>
        </p>
        <p>
        <span class="tag"><a href="#">Saas</a></span>
        <span class="tag"><a href="#">Product Branding</a></span>
        <span class="tag"><a href="#">Digital marketing</a></span>
        <span class="tag"><a href="#">SEO</a></span>
        <span class="tag"><a href="#">Domain Services</a></span>
        <span class="tag"><a href="#">Email Services</a></span>
        <span class="tag"><a href="#">Hosting Solutions</a></span>
            </p>
            
            <a className="button-width-190 button-primary button-circle button-lg button offset-top-30" href="/services">view all</a>
            
            
        </div>
    
        </div>
    )
}

export default About;