import { Helmet } from "react-helmet";

const Content=()=>{
   
    return(
        <div>
            <Helmet>
        <title>Best Content Creation Services in Palakkad</title>
        <meta name='title' content='Best Content Creation Services in Palakkad'/>
        <meta name="description" content="Get Engaging Content Creation Services: Blog Writing, Website Content & More by Trice Technologies" />
        <meta name="keywords" content="web design Palakkad, website design in Kerala, Palakkad web development company, web designing Palakkad, website design company in Palakkad, web development company in Palakkad, domain registration Palakkad, web Hosting company Palakkad" />
        <link rel="canonical" href="https://tricetechnologies.in/Best-Content-Creation-Services-in-palakkad" />
        
        <meta property="og:title" content="Best Content Creation Services in Palakkad" />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://tricetechnologies.in/Best-Content-Creation-Services-in-palakkad" />
        <meta property="og:image" content="https://tricetechnologies.in/images/services/content-creation.webp" />
        <meta property="og:description" content="Get Engaging Content Creation Services: Blog Writing, Website Content & More by Trice Technologies" />
      </Helmet>
             <section class="section section-intro context-dark" style={{height: '500px'}}>
             <div class="intro-bg" style={{
       backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg1.webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
      }}></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-8 text-center">
                  <h1 class="font-weight-bold wow fadeInLeft" style={{fontSize: '60px'}}>Content Creation</h1>
                  <p class="intro-description wow fadeInRight"></p>
                </div>
              </div>
            </div>
          </section>

         <div class="service-img">
            <div class="container con-img" >
                
                <img src={`${process.env.PUBLIC_URL}/images/services/content-creation.webp`} alt="content creation"   className="serve-img"/>
            </div>
            
         </div>
         <div class="container">
            <div class="service-content con-sec">
                <h2>Elevate Your Social Media Presence with Engaging Content</h2>
                <p>At Trice Technologies, we recognize that compelling content is essential for a successful social media strategy. Our expert team specializes in creating high-quality social media content, including eye-catching posters, engaging videos, and impactful graphics, tailored to your brand's unique voice and audience. Whether you're a startup, a small business, or a large corporation, we provide customized content solutions that drive engagement and foster brand loyalty.</p>
                
             </div>
            
         </div>
         <div class="container" style={{paddingBottom: '2%'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="photography" style={{paddingTop: '3%',transform: 'translateY(-36px)'}} >
                            <div class="col-md-12">
                                <div class="row icon-img" >
                                    <div class="col-md-1">
                                        <img src={`${process.env.PUBLIC_URL}/images/services/poster.webp`} style={{position: 'absolute',right: '0'}} alt="poster" class="imgs"/> 
                                    </div>
                                    <div class="col-md-10">
                                        <h3>Social Media Posters</h3>
                                    </div>
                                </div>
                            </div>
                            
                            <ul>
                                <p class="list-title">Promotional Posters</p>
                                <li>
                                    <ul>
                                        <li>Create visually appealing promotional posters that capture attention and drive action.</li>
                                        <li>Ideal for announcements, sales, and special events.</li>
                                    </ul>
                                </li>
                                <li class="list-title">Infographics</li>
                                <li>
                                    <ul>
                                        <li>Design informative and shareable infographics that convey complex information in an easily digestible format.</li>
                                        <li>Perfect for educational content, statistics, and step-by-step guides</li>
                                    </ul>
                                </li>
                                <li class="list-title">Quotes and Memes</li>
                                <li>
                                    <ul>
                                        <li>Develop inspirational quotes and entertaining memes that resonate with the audience<br></br> and encourage sharing</li>
                                        <li>Great for increasing engagement and brand awareness</li>
                                    </ul>
                                </li>
                            </ul>
                         </div>
                    </div>
                    <div class="row">
                        <div class="photography" style={{paddingTop: '2%',transform: 'translateY(-36px)'}}>
                            <div class="col-md-12">
                                <div class="row icon-img" >
                                    <div class="col-md-1">
                                        
                                        <img src={`${process.env.PUBLIC_URL}/images/services/video.webp`} style={{position: 'absolute',right: '0'}} alt="video" class="imgs"/> 
                                    </div>
                                    <div class="col-md-10">
                                        <h3 >Social Media Videos</h3>
                                    </div>
                                </div>
                            </div>
                            
                            <ul>
                                <li class="list-title">Short Clips and Reels</li>
                                <li>
                                    <ul>
                                        <li>Produce short, engaging video clips and reels that capture the essence of your message in a few seconds.</li>
                                        <li>Ideal for Instagram, TikTok, and Facebook stories.</li>
                                    </ul>
                                </li>
                                <li class="list-title">Explainer Videos</li>
                                <li>
                                    <ul>
                                        <li>Create informative explainer videos that simplify complex topics and highlight your product or service benefits.</li>
                                        <li>Essential for YouTube, LinkedIn, and website use.</li>
                                    </ul>
                                </li>
            
                                <li class="list-title">Behind-the-Scenes Videos</li>
                                <li>
                                    <ul>
                                        <li>Offer a glimpse behind the scenes of your business, showcasing your team, processes, and company culture.</li>
                                        <li>Build trust and connection with your audience.</li>
                                    </ul>
                                </li>
                            </ul>
                         </div>
                    </div>


                    <div class="row">
                        <div class="photography" style={{paddingTop: '2%',transform: 'translateY(-36px)'}}>
                            <div class="col-md-12">
                                <div class="row icon-img" >
                                    <div class="col-md-1">
                                         <img src={`${process.env.PUBLIC_URL}/images/services/design.webp`} alt="design" style={{position: 'absolute',right: '0'}} class="imgs"/> 
                                    </div>
                                    <div class="col-md-10">
                                        <h3 >Graphic Design</h3>
                                    </div>
                                </div>
                            </div>
                            
                            <ul>
                                <li class="list-title">Custom Graphics</li>
                                <li>
                                    <ul>
                                        <li>Design unique graphics that align with your brand’s identity and aesthetic.</li>
                                        <li>Suitable for social media posts, blog visuals, and website graphics.</li>
                                    </ul>
                                </li>
                                <li class="list-title">Banner Ads</li>
                                <li>
                                    <ul>
                                        <li>Create striking banner ads that stand out and drive clicks.</li>
                                        <li>Perfect for online advertising campaigns on various platforms</li>
                                    </ul>
                                </li>
            
                                <li class="list-title">Event Graphics</li>
                                <li>
                                    <ul>
                                        <li>Develop event-specific graphics that generate excitement and encourage participation.</li>
                                        <li>Ideal for webinars, live events, and promotional campaigns.</li>
                                    </ul>
                                </li>
                            </ul>
                         </div>
                    </div>
                </div>
                
                        <img src={`${process.env.PUBLIC_URL}/images/services/cc1.webp`} alt="photography-1" class="photography-img2"/>
                        <img src={`${process.env.PUBLIC_URL}/images/services/cc2.webp`} alt="photography-2" class="photography-img1"/>

                </div>
            </div>
        </div>
    )
}

export default Content;