import React, { useState } from "react";


const WebFAQ = () => {
  // Define the state to handle which FAQ is open
  const [openIndex, setOpenIndex] = useState(null);

  // Sample FAQ data
  const faqData = [
    { question: "How long would it take for Trice Technologies to build a website?", answer: "The duration to develop a website depends on the level of detail and features needed. On an average, a standard website can take around 5 to 8 weeks to complete. We'll be able to provide you with a clear timeline after discussing your requirements." },
    { question: "How much would it cost to build a new website with Trice Technologies?", answer: "Website development costs vary depending on the goal, layout, and facilities required. We offer custom pricing based on your unique needs. Contact us for a detailed quote after sharing your project details." },
    { question: "How to make my website SEO optimized?", answer: "We create SEO-friendly websites by carefully optimizing the features like structure of the website, contents used, keywords included, meta tags, and images too. Our development and SEO team together ensures that your website is responsive for all platforms, loading fast etc. which are the key factors of SEO." },
    { question: "Why is a mobile-friendly or responsive website important to have?", answer: "These days’ people are more connected to mobile other than desktops or laptops. So it is necessary to ensure your site is responsive to make sure that users can access the same from all type of devices. It levels up the user experience and in turn improves the ranking on various search engines and reaches a wider audience." },
    { question: "Can I see my website while it’s in progress?", answer: "Yes, we give regular updates and a preview of your website during the development process. You can review and suggest changes at any stage before the final launch of your website." },
    { question: "How to choose the best web designing company in Palakkad?", answer: "Look for a company with experience and skillset in creating websites focused to your needs, a strong portfolio, and a team of skilled designers and developers. Trice Technologies stands out for its customer-centric approach and commitment to delivering high-quality, user-friendly websites." },
    { question: "What makes your company different?", answer: "Trice Technologies blends technical knowhow with a deep understanding of client requirements. We offer personalized service, attention to detail, and modern solutions, ensuring that each website we build is not only active but also goes with your business goals." },
    { question: "I want to create a website for my startup business. What is the process?", answer: "The process starts with an initial consultation to understand your business, expected outcomes, and requirements. Next, we’ll create a unique plan, including design, development, and SEO strategy. Once the website is developed, we’ll provide you with a review, make necessary adjustments, and then launch it." }
];

  // Toggle the open state for each FAQ item
  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close if the same index is clicked again
    } else {
      setOpenIndex(index); // Open the clicked FAQ
    }
  };

  return (
    <div className="faq-container" data-aos='fade-up'>
      <h2 className="text-left" style={{color:'#000'}}>FAQs</h2>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`faq-item ${openIndex === index ? "open" : ""}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <span>{item.question}</span>
              <span className="faq-icon">{openIndex === index ? "-" : "+"}</span>
            </div>
            <div className="faq-answer">{item.answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WebFAQ;
