import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './VerticalCarousel.css';

function UncontrolledExample() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if screen width is less than or equal to 768px (typical tablet/mobile breakpoint)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check on mount
    handleResize();

    // Listen to window resize events
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='container' style={{maxWidth: '90%'}}>
      <Carousel>
        <Carousel.Item>
          <img
            src={`${process.env.PUBLIC_URL}/images/banner/slider1${isMobile ? '-mobile' : ''}.webp`}
            style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
            className="slider-img"
            alt="Best web development palakkad.
webdesign palakkad,
webdevolpment palakkad"
            
            height="auto"
            width="auto"
          />
          <Carousel.Caption>
            {/* Caption Content */}
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            src={`${process.env.PUBLIC_URL}/images/banner/slider2${isMobile ? '-mobile' : ''}.webp`}
            style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
            className="slider-img"
            alt="digital marketing palakkad,
best digital marketing palakkad,
digital marketing company palakkad"
             height="auto"
            width="auto"
            loading="lazy"
          />
          <Carousel.Caption>
            {/* Caption Content */}
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            src={`${process.env.PUBLIC_URL}/images/banner/slider3${isMobile ? '-mobile' : ''}.webp`}
            style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
            className="slider-img"
            alt="best seo palakkad
seo palakkad
seo agency palakkad"
             height="auto"
            width="auto"
            loading="lazy"
          />
          <Carousel.Caption>
            {/* Caption Content */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default UncontrolledExample;
