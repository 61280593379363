import { Helmet } from "react-helmet";

const DigitalMarketing=()=>{

    return(
        <div>
          <Helmet>
        <title>Best digital marketing strategist in palakkad | Trice technologies</title>
        <meta name='title' content='Best digital marketing strategist in palakkad | Trice technologies'/>
        <meta name="description" content="Trice Technologies is a Best digital marketing strategist in palakkad, Offering top digital marketing services with expert strategists." />
        <meta name="keywords" content="web design Palakkad, website design in Kerala, Palakkad web development company, web designing Palakkad, website design company in Palakkad, web development company in Palakkad, domain registration Palakkad, web Hosting company Palakkad" />
        <link rel="canonical" href="https://tricetechnologies.in/best-digital-marketing-strategist-in-palakkad" />
        
        <meta property="og:title" content="Best digital marketing strategist in palakkad | Trice technologies" />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://tricetechnologies.in/best-digital-marketing-strategist-in-palakkad" />
        <meta property="og:image" content="https://tricetechnologies.in/images/services/dm.webp" />
        <meta property="og:description" content="Best digital marketing strategist in palakkad, Offering top digital marketing services with expert strategists." />
        <meta name="keywords" content="web design Palakkad, website design in Kerala, Palakkad web development company, web designing Palakkad, website design company in Palakkad, web development company in Palakkad, domain registration Palakkad, web Hosting company Palakkad" />
      
        <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "Product",
            "name": "Best digital marketing strategist in palakkad | Trice technologies",
            "image": "https://tricetechnologies.in/images/services/dm.webp",
            "description": "Best digital marketing strategist in palakkad, Offering top digital marketing services with expert strategists.",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.9",
              "reviewCount": "19"
            }
          }
        `}
      </script>  
      </Helmet>
            <section class="section section-intro context-dark" style={{height: '500px',backgroundColor: '#0e0e10'}}>
            <div class="intro-bg" style={{
       backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg1.webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
      }}></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-8 text-center">
                  <h1 class="font-weight-bold wow fadeInLeft web">Best digital marketing strategist in palakkad
                  Rule other businesses out of competition with a power-packed online presence</h1>
                  <p class="intro-description wow fadeInRight"></p>
                </div>
              </div>
            </div>
          </section>

         <div class="service-img">
            <div class="container">
                
                <img src={`${process.env.PUBLIC_URL}/images/services/dm.webp`} alt="Best digital marketing strategist in palakkad" class="serve-img"/>
            </div>
            
         </div>
         <div class="container">
            <div class="service-content dig">
                <h2>Top Digital Marketing Company in Palakkad
                Rule other businesses out of competition with a power-packed online presence</h2>
                <p>that helps B2B and B2C businesses to create a powerful digital footprint and get their cash registers clicking all day with a personalized digital marketing solution.Our team ensures that you get the best digital marketing services in Palakkad. We provide intuitive and effective digital marketing strategies that will generate instant leads. So, take your business global with just a single click.</p>
            </div>
           
         </div>
         <div class="process dig-p">
            <p className="pb-2 p1" style={{textAlign: 'center',fontWeight: '700'}}>Through our proven strategies, expect guaranteed results from day 1 To put it in numbers </p>
            <h3 className="pb-3" style={{color: '#a4a5a9'}}>OUR CONTENT MARKET STRATEGY WILL GENERATE FREE CONSTANT TRAFFIC</h3>
            <div class="container">
              <div class="row">
                <section class="services-section" >
                 
                  <div class="col-md-12 col-sm-12 service-card" >
                    <div class="service-icon">
                      <span class="service-number"  style={{fontSize: '65px'}}><b class="border-text">01</b></span>
                  </div>
                      <div class="contents">
                        <h3 class="service-title">SEARCH ENGINE OPTIMIZATION (SEO)</h3>
                        <p class="service-description">Your website will rank automatically at the top of search engine result pages through our website optimization techniques. Our experts use data-driven optimization tools and techniques to rank your website #1 on search engines. Through our effective SEO techniques, get unstoppable traffic on your website and get noticed by hundreds of thousands of customers.</p>
                      </div>
                      
                  </div>
                  <div class="col-md-12 col-sm-12 service-card" >
                      <div class="service-icon">
                          <span class="service-number"  style={{fontSize: '65px'}}><b class="border-text">02</b></span>
                      </div>
                      <div class="contents">
                        <h3 class="service-title">SOCIAL MEDIA MARKETING (SMM)</h3>
                      <p class="service-description">No digital marketing campaign is complete without an effective social media marketing campaign. Social media platforms are windows to guaranteed business expansions. Our team understands its power and designs social media strategies to take your brand name to probable buyers. Get expert guidance in digital marketing through us and more leads in less than 5 minutes!</p>
                      </div>
                      
                  </div>
                  <div class="col-md-12 col-sm-12 service-card" >
                      <div class="service-icon">
                          <span class="service-number"  style={{fontSize: '65px'}}><b class="border-text">03</b></span>
                      </div>
                      <div class="contents">
                        <h3 class="service-title">CONTENT MARKETING</h3>
                        <p class="service-description">Whether you own a startup or a top MNC, content marketing will benefit your online business by a landslide than traditional marketing. You can turn content consumers into customers through our impactful content conversion marketing strategy. So, rank your business high on SERPs, boost website traffic, and get jaw-dropping conversions for your business through our results-driven content marketing strategies created by the best minds in the industry and backed by the latest technical know-how.</p>
                      </div>
                      
                  </div>

                  <div class="col-md-12 col-sm-12 service-card" >
                      <div class="service-icon">
                          <span class="service-number" style={{fontSize: '65px'}}><b class="border-text">04</b></span>
                      </div>
                      <div class="contents">
                        <h3 class="service-title">GOOGLE PAY PER CLICK ADVERTISING (PPC)</h3>
                        <p class="service-description">Google Pay per Click Advertising or Search Engine Marketing (SEM) increases website visibility through paid promotions. PPC helps you target unattended customers from the internet. Through PPC, you can captivate new customers, bring them to your website, and thus, boost your sales. PPC is a ready digital marketing strategy to make any business an overnight sensation. At Trice Technologies, our expert team focuses on driving the maximum possible traffic to your website at the lowest PPC cost. We promise the best conversion rates at the most affordable prices in the market. So, increase your ROI and drive meaningful traffic to your website by making your business an instant hit on various social media channels and search engines.</p>
                      </div>
                      
                  </div>
              </section>
              </div>
               
            </div>
           
           
         </div>
         <div class="container"style={{marginTop: '3%'}}>
            
            <div class="row d1">
                
                <div class="col-md-6 col-sm-12">
                 
                    <h3>HOW DO WE STAND OUT FROM THE OTHER
                        DIGITAL MARKETING AGENCIES IN PALAKKAD?</h3>
                        <img src="../images/services/digital marketing.webp" alt="digital marketing company in Palakkad , best digital marketing services ,digital marketing palakkad" style={{marginLeft : '20%'}}/>
                       
                        
                </div>
                <div class="col-md-6 col-sm-12">
                  <ul style={{textAlign: 'left',listStyle: 'none'}} class="steps">
                      <li><div><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow1"/> </span><b>Consistent, genuine results</b></div>We provide the best-selling end-to-end digital marketing services in Palakkad. Our services are dependable and bear actual results with a free demonstration session.</li>
                      <li><div><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow2"/> </span><b>24x7 client support</b></div>Our experts are available round the clock and are just a phone call away. We have a team of reliable, high proficient marketing experts who will help you out with all nitty-gritty of the project before you invest in it. So, at Trice Technologies, you get to try before you buy.</li>
                      <li><div><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow3"/> </span><b>Continuous Performance Tracking</b></div>Our experts at Trice Technologies monitor and manage social media marketing, search engine marketing, and other paid promotions every month. So, you can always stay ahead of the game through easy tracking.</li>
                      <li><div><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow4"/> </span><b>Proven ROI boost</b></div>As we provide the best digital marketing services in Palakkad, we have an unbeaten record of landslide increase in ROI for business through our campaigns. We offer recession-proof strategies to your business that will sail you through the most challenging economic times.</li>
                  </ul>
              </div>
                
            </div>
        </div>
        </div>
    )
}

export default DigitalMarketing;